<template>
  <div class="login-wrap">
    <div class="login-check">
      <div>
        <Tabs value="account" :animated="false" @on-click="name => tabName = name">
          <TabPane label="账号密码登录" name="account">
            <Form ref="passwordValidate" :model="passwordFrom" :rules="passwordRules">
              <FormItem  prop="account">
                <!-- prefix="ios-contact" -->
                <Input
                  v-model.trim="passwordFrom.account"
                  placeholder="手机号/用户名/邮箱"
                  style="width: 100%"
                >
                  <img class="icon" src="@/assets/imgs/login/icon-account.png" slot="prefix">
                </Input>
              </FormItem>
              <FormItem prop="password">
                <Input
                  v-model.trim="passwordFrom.password"
                  type="password"
                  password
                  placeholder="请输入密码"
                  style="width: 100%"
                >
                  <img class="icon" src="@/assets/imgs/login/icon-password.png" slot="prefix">
                </Input>
              </FormItem>
            </Form>
            <div class="forget-password" >
              <span @click="forgetPassword">忘记密码?</span>
            </div>
          </TabPane>
          <TabPane label="验证码登录" name="code">
            <Form ref="formValidate" :model="formCustom" :rules="ruleCustom" @submit.native.prevent>
              <FormItem  prop="mobileNum">
                <Input
                  v-model.trim="formCustom.mobileNum"
                  placeholder="手机号"
                  maxlength="11"
                  style="width: 100%"
                  clearable
                  @input.native="formCustom.mobileNum = formCustom.mobileNum.replace(/\D/g, '')"
                  @on-blur="phoneBlur"
                >
                  <img class="icon" src="@/assets/imgs/login/icon-account.png" slot="prefix">
                </Input>
              </FormItem>
            </Form>
            <div class="code-btn-wrap">
              <Input
                v-model.trim="code"
                prefix="ios-contact"
                placeholder="验证码"
                type="text"
                style="flex:1;margin-bottom: 0;margin-right:10px"
              >
                <img class="icon" src="@/assets/imgs/login/icon-code.png" slot="prefix">
              </Input>
              <div class="code-btn" @click="getCode" v-if="checkFlag">{{firstFlag?'获取验证码':'重新发送'}}</div>
              <div class="countDown-btn" v-else>倒计时{{countDownTime}}s</div>
            </div>
            <span style="font-size:12px;color:#666">未注册的手机号验证后即完成注册</span>
          </TabPane>
        </Tabs>
      </div>
      <Button type="error" @click="login" :disabled="btnDisabled" long>登录</Button>
      <!-- 暂时屏蔽微信登录,记得去掉下一个div的上边距 -->
      <div class="other-methods">
        <span>其他登录方式</span>
        <img @click="weChatLogin" src="@/assets/imgs/login/icon-weixin.png" alt="">
      </div>
      <div class="rules">
        <Checkbox v-model="single">
          同意
          <span @click="$router.push('/registerAgreement')" :style="{color:single ? '#0085ff':'#999'}">《用户协议》</span>
          和
          <span @click="$router.push('/privacyAgreement')"  :style="{color:single ? '#0085ff':'#999'}">《隐私声明》</span>
        </Checkbox>
      </div>
      <div id="captcha"></div>
      <!-- 微信登录二维码弹窗 -->
      <Modal v-model="codeDialog" :footer-hide="true" width="500">
        <div class="codeDialog">
          <p class="title">微信登录</p>
          <img :src="codeUrl" alt="">
          <p>请使用手机微信扫描二维码登录</p>
          <p>“格力商城”</p>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { accountLogin,getCode,codeLogin,checkLogin,weChatAuthorization} from '@/api/api'
import { rasEncrypt ,checkLoginConfig} from '@/utils/common'
export default {
  name: 'Login',
  data() {
    const validatePhone = (rule, value, callback) => {
      const regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if(regPhone.test(value)){
        callback()
      }else{
        callback(new Error('请输入正确的手机号码！'))
      }
    }
    return {
      single: true,
      tabName:'account',
      code:'',
      countDownTime:null,
      firstFlag:true,
      checkFlag:true,
      captchaIns:null, //网易网盾插件实例
      passwordFrom:{
        account:'',
        password:''
      },
      passwordRules:{
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
        ]
      },
      formCustom:{
        mobileNum:'',
      },
      ruleCustom:{
        mobileNum: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ]
      },
      codeDialog: false,
      codeUrl: '',
      overIpCount5:''
    }
  },
  computed: {
    btnDisabled() {
      return this.tabName === 'account'?!(this.single  && this.passwordFrom.password):!(this.formCustom.mobileNum&& this.code && this.single)
    },
  },
  mounted() {
    // 网易网盾配置
    checkLoginConfig({
      init: (instance) => {
        // 初始化成功后得到验证实例instance，可以调用实例的方法
        this.captchaIns = instance
      },
      afterCheck: (val) => {
        // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
        this.captchaIns.close()
        let query = this.tabName === 'account'?{
          captchaValidateValue: val.validate,
          mobileNumber: this.passwordFrom.account,
          flag:this.overIpCount5
        } : {
          captchaValidateValue: val.validate,
          mobileNumber: this.formCustom.mobileNum,
       }
        checkLogin(query).then((res) => {
          if (res.result === 0) {
            if (this.overIpCount5) {
              this.accountLogin()
              this.captchaIns.refresh() 
								this.overIpCount5 = ''
								return
							}
            this.getCode() //发送验证码
          }
          else {
							this.overIpCount5 = ''
						}
        })
      },
    })
  },
  methods: {
    /* 登录 */
    async login() {
      if(this.tabName === 'account'){
        this.accountLogin()
      }else if(this.tabName === 'code'){
        this.codeLogin()
      }
    },
    /* 账号密码登录 */
    async accountLogin(){
      this.$refs.passwordValidate.validate(async valid => {
        if(!valid) return
        const { result, message, data } = await accountLogin({
          loginAccount: this.passwordFrom.account,
          pwd: rasEncrypt(this.passwordFrom.password),
          loginFlag: 'pcMall',
        })
        if(result === 0){
          this.$store.commit('SET_USER_INFO', data)
          this.$emit('cart');
          if(this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect)
          } else {
            this.$router.replace({ path: '/home' })
          }
        }
        else if (result === 40109) {
          this.overIpCount5 = 'mallPwdLoginIpCount'
          this.captchaIns.popUp()
								}  
        else {
          this.$Message.error({
            content: message,
            duration: 2,
          })
        }
      })
    },
    /* 验证码登录 */
    async codeLogin(){
      this.$refs.formValidate.validate(async valid => {
        if(!valid) return
        const {result,message,data} = await codeLogin({
          mobile: this.formCustom.mobileNum,
          smsCode: this.code,
          loginFlag: 'pcMall',
        })
        if(result === 0){
          this.$store.commit('SET_USER_INFO', data)
          this.$emit('cart');
          if(this.$route.query.redirect) {
            this.$router.replace(this.$route.query.redirect)
          } else {
            this.$router.replace({ path: '/home' })
          }
        }else{
          this.$Message.error({
            content: message,
            duration: 2,
          })
        }
      })
    },

    /* 微信登录 */
    weChatLogin(){
      console.log('微信登录');
      weChatAuthorization({callBackUrl: ''}).then(res => {
        // 后端返回生成的二维码
        window.location.href = res.data.redirectUrl
        // if (res.data.result === 0 ) {
        //   this.codeDialog  = true
        //   this.codeUrl  = res.data.redirectUrl
        // }
      })
    },
    /* 忘记密码 */
    forgetPassword() {
      this.$router.push('/forget-password')
    },

    /* 手机号失焦 */
    phoneBlur(){
      this.$refs.formValidate.validateField('mobileNum',async valid => {
        if(!valid && !this.firstFlag){
          this.firstFlag = true
        }
      })
    },
    /* 倒计时 */
    timeCountDown(time){
      this.countDownTime = time
      let timer = setInterval(() => {
        this.countDownTime -=1
        if(this.countDownTime <= 0){
          this.checkFlag = true
          clearInterval(timer)
        }
      },1000)
    },
    /* 获取验证码 */
    getCode(){
      this.$refs.formValidate.validateField('mobileNum',async valid => {
        if(valid) return
        const {result,message} =  await getCode({ mobileNumber: this.formCustom.mobileNum, messageFlag: 'PC_SMS_LOGIN' })
        switch (result) {
          case 0:
            this.$Message.success({
              content: '短信验证码发送成功',
              duration: 2,
            })
            this.checkFlag = false
            this.firstFlag = false
            this.timeCountDown(60)
            break;
          case 2233:
          case 2232:
            this.$Message.warning({
              content: message,
              duration: 2,
            })
            break
          case 2231: //人工滑块
            this.captchaIns.popUp()
            break
          default:
            break;
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.login-wrap {
  width: 100%;
  position: relative;
  height: 500px;
  background-color: gray;
  background: url('https://mall.gree.com/assets/images/login_bg_normal.png')
    no-repeat;
  background-size: 100% 100%;
}

.login-check {
  position: absolute;
  top: 50%;
  right: 350px;
  transform: translateY(-50%);
  width: 334px;
  height: 340px;
  background-color: #fff;
  padding: 30px 26px 10px;
}

.icon{
  width: 16px;
}
/deep/.ivu-input-prefix{
  line-height: 36px;
}
/deep/.ivu-btn-error[disabled] {
    color: #c5c8ce;
    background-color: #f7f7f7;
    border-color: #dcdee2;
}
/deep/.ivu-btn-error {
  color: #FFF;
  background-color: #ef2f2f;
  border-color: #ef2f2f;
}
/deep/.ivu-tabs {
  color: #000;
  .ivu-tabs-bar {
    border: none;
    .ivu-tabs-nav{
      width: 100%;
      display: flex;
      justify-content: center;
      .ivu-tabs-tab{
        font-size: 16px;
      }
      .ivu-tabs-ink-bar{
        display: none;
      }
      .ivu-tabs-tab-focused{
        border-bottom: 3px solid #EF2F2F !important;
      }
    }
    .ivu-tabs-tab-active {
      color: #EF2F2F;
    }
    .ivu-tabs-ink-bar {
      background-color: #EF2F2F;
    }
  }
  .ivu-input-wrapper {
    display: block;
    margin-bottom: 25px;
  }
}

.forget-password {
  color: #1890FF;
  text-align: right;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
}

/deep/.ivu-btn {
  width: 100%;
  margin-top: 10px;
}
/deep/.ivu-form-item{
  margin-bottom: 0;
}
.code-btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -1px 0 20px;
  .code-btn {
    width: 100px;
    height: 34px;
    border: 1px solid #dcdee2;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    color: #ef2f2f;
    font-size: 14px;
  }
  .countDown-btn{
    width: 100px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    color: #ef2f2f;
    border: 1px solid #dcdee2;
    font-size: 14px;
  }
}

.other-methods {
  margin: 15px 0;
  font-size: 12px;
  img{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
  }
}
.rules {
  text-align: center;
  margin-left: 10px;
}
/deep/.ivu-checkbox-wrapper{
  font-size: 13px;
  margin-right: 0;
}
.codeDialog {
  text-align: center;
  .title {
    font-size: 20px;
  }
}
</style>